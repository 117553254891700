import React from "react"
import Layout from "../components/Layout"
import Status from "../components/Status"
import { Router } from "@reach/router"
import withJWT from "../components/withJWT"
import ForgotPwd from "../components/ForgetPwd"
import { VISITOR_DONATION } from "../utils/constants"

const NewPassword = (props) => {

  return (
    <Layout>
      <Status {...props} menuoptions={{ logout: false,
        close: false,
        returnToDonation: false,
        visitorType: VISITOR_DONATION
      }} />
      <Router>
        <ForgotPwd path="/newpassword"/>
      </Router>
    </Layout>
  )
}

export default withJWT(NewPassword)
