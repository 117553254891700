import React from "react"
import View from "../View"
import * as styles from "./main.module.css"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api_base_url } from "../../utils/auth"
import { navigate } from "@reach/router"
import withLocation from "../withLocation"
import queryString from "query-string"

const axios = require('axios').default;

function uppercaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ForgotPwd = (props) => {
  let queryParams;
  if (props.location) {
    queryParams = queryString.parse(props.location.search)
  }

    if (queryParams.username && queryParams.code) {
      return (
        <div className="ml-3 mr-3">
          <div className={["container-fluid"].join(" ")}>
            <div className={["row", "justify-content-center", styles.container].join(" ")}>

              <View>
                <div className={["text-left", "mb-4", styles.container].join(" ")}>
                  {uppercaseFirstLetter(queryParams.username)}, använd formuläret nedan för att sätta ett nytt lösenord
                </div>
                <Formik initialValues={{
                  pwd: "",
                  pwd_repeat: ""
                }}
                        validationSchema={Yup.object().shape({
                          pwd: Yup.string()
                            .min(6, 'Lösenordet måste innehålla minst 6 bokstäver')
                            .required('Du måste ange ett lösnord'),
                          pwd_repeat: Yup.string()
                            .oneOf([Yup.ref('pwd'), null], 'Lösenordet matchar inte')
                            .required('Du måste bekräfta lösenordet')
                        })}
                        onSubmit={(values, actions) => {

                          const options = {
                            method: 'post',
                            url: `${api_base_url()}/passwordchange`,
                            data: {
                              username: queryParams.username,
                              code: queryParams.code,
                              new_password: values.pwd
                            }
                          };

                          axios(options)
                            .then(response => {
                              actions.setSubmitting(false);
                              navigate("/app/login");
                            })
                            .catch(error => {
                              actions.setSubmitting(false);
                                actions.setFieldError('signatur', "Kunde inte uppdatera lösenordet");
                            });
                        }}>
                  {({ handleSubmit, errors, touched, isValidating, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}
                          className={["text-left", "font-weight-bold", styles.container].join(" ")}>
                      <div className="form-group">
                        <label htmlFor="pwd">Lösenord</label>
                        <Field type="password" name="pwd"
                               className={'form-control' + (errors.pwd && touched.pwd ? ' is-invalid' : '')}/>
                        <ErrorMessage name="pwd" component="div" className="invalid-feedback"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="pwd_repeat">Lösenord igen</label>
                        <Field type="password" name="pwd_repeat"
                               className={'form-control' + (errors.pwd_repeat && touched.pwd_repeat ? ' is-invalid' : '')}/>
                        <ErrorMessage name="pwd_repeat" component="div" className="invalid-feedback"/>
                      </div>
                      <button className={["btn", "btn-primary"].join(" ")} type="submit"
                              disabled={isSubmitting}>Uppdatera lösenordet
                      </button>
                    </Form>
                  )}
                </Formik>
              </View>
            </div>
          </div>
        </div>

      )
    }
    else {
      return (
        <div className="ml-3 mr-3">
          <div className={["container-fluid"].join(" ")}>
            <div className={["row", "justify-content-center", styles.container].join(" ")}>

              <View>
                <div className={["text-left", "mb-4", styles.container].join(" ")}>

                </div>
              </View>
            </div>
          </div>
        </div>
      )
    }
}


export default withLocation(ForgotPwd)
